import { Button, Col, Container, Input, Row } from 'reactstrap';

import { Room } from '../types';
import { useContext } from 'react';
import { AppContext, BookingDetails, PricingOptions } from '../AppContext';
import { useParams } from 'react-router-dom';
import { usePricing } from '../usePricing';
import { Trans, useTranslation } from 'react-i18next';

function BookingView({ room, onBook, onRefuse }: { room?: Room, onBook?: () => void, onRefuse?: () => void }) {
    const { answers, setAnswer } = useContext(AppContext);
    const { experimentId = "-1" } = useParams<
        {
            experimentId: string;
            stepId: string;
        }>();


    const { t } = useTranslation();

    const booking = (answers[experimentId ?? "-1"]?.[-1]?.[-1] ?? {}) as BookingDetails;
    const price = usePricing(booking);

    const options_to_put_in_select = [
        "breakfast",
        "half_board",
        "full_board"
    ];

    return (
        <Container style={{ alignSelf: "center" }}>
            <h4>{t('options')}</h4>
            <hr></hr>

            <Row className='d-flex justify-content-center'>
                <Col xs={12} lg={3} className='d-flex justify-content-center'><h4>{t('board')}</h4></Col>
                <Col xs={12} lg={3} className='d-flex justify-content-center'>
                    <Input type="select"
                        onChange={(event) => {
                            const newBooking: BookingDetails = {
                                ...booking,
                                selectedOptions: event.target.value !== "" ?
                                    [...booking.selectedOptions.filter(e => !options_to_put_in_select.includes(e)), event.target.value as PricingOptions] :
                                    booking.selectedOptions.filter(e => !options_to_put_in_select.includes(e)),
                            };
                            setAnswer(parseInt(experimentId), -1, -1, newBooking);
                        }}>
                        <option value="" selected>{t('only_room')}</option>
                        {
                            room?.attributes.options?.filter(e => options_to_put_in_select.includes(e.identifier || ""))
                                .map((option, i) => {
                                    return (<option key={i} value={option.identifier || ""}>{option.option}</option>);
                                })
                        }
                    </Input>
                </Col>
            </Row>
            <div>
                {
                    room?.attributes.options?.filter(e => !options_to_put_in_select.includes(e.identifier || "")).map((option, i) => {
                        return (<div className='m-2 d-flex align-items-center justify-content-center' key={i}>
                            <Input id={`element${i.toString()}`} key={i.toString()} type="checkbox" className='me-2' onChange={(event) => {
                                const newBooking: BookingDetails = {
                                    ...booking,
                                    selectedOptions:
                                        [
                                            ...booking.selectedOptions.filter(e => option.identifier !== e),
                                            ...(event.target.checked ? [option.identifier as PricingOptions] : [])
                                        ]
                                };
                                setAnswer(parseInt(experimentId), -1, -1, newBooking);
                            }} /> <label htmlFor={`element${i.toString()}`} style={{ fontSize: "1.3em" }}>{option.option}</label>
                        </div>);
                    })
                }
            </div>
            <h4>
                <Trans i18nKey={'booking_question'} values={{
                    persons: booking.adults + booking.children,
                    nights: booking.nights,
                    totalPricing: price.totalPricing.toFixed(2),
                    pricePerNight: (price.totalPricing / booking.nights).toFixed(2)
                }}></Trans>
            </h4>
            <div className='d-flex justify-content-center mt-4' style={{ borderTop: '1px white solid', padding: 10 }}>
                <Button color="success" onClick={onBook}>{t('yes_reserve')}</Button>
                <div style={{ width: '30px' }}></div>
                <Button color="danger" onClick={onRefuse}>{t('no')}</Button>
            </div>
        </Container >
    );
}

export default BookingView;
