import z from 'zod';

export const DynamicQuestionSchema = z.object({
    __component: z.literal('dynamic.question'),
    id: z.number(),
    question: z.string().nullable(),
    multiple_answers: z.boolean(),
    answers: z.array(z.object({
        id: z.number(),
        text: z.string(),
    })),
    mandatory: z.boolean().nullable(),
});

export const PercentageQuestionSchema = z.object({
    __component: z.literal('dynamic.percentage-question'),
    id: z.number(),
    question: z.string().nullable(),
    answers: z.array(z.object({
        id: z.number(),
        label: z.string(),
    })),
    mandatory: z.boolean().nullable(),
});

export const NumericQuestionSchema = z.object({
    __component: z.literal('dynamic.numeric-input-question'),
    id: z.number(),
    question: z.string().nullable(),
    min: z.number().nullable(),
    max: z.number().nullable(),
    mandatory: z.boolean().nullable(),
});

export const AggreementQuestionSchema = z.object({
    __component: z.literal('dynamic.agreement-question'),
    id: z.number(),
    question: z.string().nullable(),
    mandatory: z.boolean().nullable(),
});

export const DescriptionQuestionSchema = z.object({
    __component: z.literal('dynamic.question-description'),
    id: z.number(),
    text: z.string().nullable()
});

export const QuestionSchema = z.discriminatedUnion("__component", [
    DynamicQuestionSchema,
    NumericQuestionSchema,
    AggreementQuestionSchema,
    DescriptionQuestionSchema,
    PercentageQuestionSchema,
]);

export const QuestionaireSchema = z.object({
    id: z.number(),
    attributes: z.object({
        title: z.string(),
        createdAt: z.string(),
        updatedAt: z.string(),
        locale: z.string(),
        description: z.string().nullable(),
        questions: z.array(QuestionSchema),
    }),
});



const MediaFormatSchema = z.object({
    url: z.string(),
    width: z.number(),
    height: z.number(),
});

export const MediaSchema = z.object({
    id: z.number(),
    attributes: z.object({
        name: z.string(),
        alternativeText: z.string().nullable(),
        caption: z.string().nullable(),
        width: z.number().nullable().optional(),
        height: z.number().nullable().optional(),
        formats: z.object({
            thumbnail: MediaFormatSchema.optional(),
            small: MediaFormatSchema.optional(),
            medium: MediaFormatSchema.optional(),
            large: MediaFormatSchema.optional(),
        }).nullable(),
        url: z.string(),
        mime: z.string(),
        ext: z.string(),
        size: z.number(),
        previewUrl: z.string().nullable(),
    }),
});

export const RoomSchema = z.object({
    id: z.number(),
    attributes: z.object({
        name: z.string(),
        createdAt: z.string(),
        updatedAt: z.string(),
        locale: z.string(),
        description: z.string().nullable(),
        images: z.object(
            {
                data: z.array(MediaSchema).nullable()
            }
        ),
        main_image: z.object(
            {
                data: MediaSchema.nullable()
            }
        ),
        video: z.object(
            {
                data: MediaSchema.nullable()
            }
        ),
        options: z.array(z.object(
            {
                option: z.string().nullable(),
                identifier: z.string().nullable(),
            }
        )).nullable(),

    }),
});

export const ExperimentSchema = z.object({
    id: z.number(),
    attributes: z.object({
        name: z.string(),
        createdAt: z.string(),
        updatedAt: z.string(),
        agreement: z.string().nullable(),
        questionaire_before: z.object({
            data: QuestionaireSchema,
        }),
        questionaire_after: z.object({
            data: QuestionaireSchema,
        }),
        final_questionare: z.object({
            data: QuestionaireSchema,
        }),
        questionaire_reject: z.object({
            data: QuestionaireSchema,
        }),
        room: z.object({
            data: RoomSchema,
        }),

    }),
});






//STRAPI specific schemas
export const StrapiReference = z.union([z.number(), z.object({ id: z.number() }).passthrough()]);

const BasePopulateSchema = z.union([
    z.string(),
    z.array(z.string()),
]);

type StrapiPopulate = z.infer<typeof BasePopulateSchema> | {
    [key: string]: StrapiPopulate | boolean;
};

export const StrapiFieldsSchema = z.union([z.string(), z.array(z.string())]).optional();

export const StrapiPopulateSchema: z.ZodType<StrapiPopulate> = z.lazy(() => z.union([
    BasePopulateSchema,
    z.record(z.union([z.boolean(), StrapiPopulateSchema])),
]));

export const StrapiMetaSchema = z.object({
    pagination: z.object({
        page: z.number(),
        pageCount: z.number(),
        pageSize: z.number(),
        total: z.number(),
    }).optional(),
});
//END STRAPI specific schemas


export type Experiment = z.infer<typeof ExperimentSchema>;
export type Questionaire = z.infer<typeof QuestionaireSchema>;
export type Question = z.infer<typeof QuestionSchema>;
export type Room = z.infer<typeof RoomSchema>;
