import './MainPage.css';
import { useContext, useEffect, useState } from 'react';

import { AppContext, BookingDetails } from '../AppContext';
import { useNavigate, useParams } from 'react-router-dom';
import { Experiment } from '../types';
import QuestionaireView from '../views/QuestionaireView';
import RoomVideoView from '../views/RoomVideoView';
import RoomDetailsView from '../views/RoomDetailsView';
import BookingView from '../views/BookingView';
import { liectApi } from '../apis/liect.org';
import { META_EXPERIMENT_END_TIME } from './MainPage';
import { usePricing } from '../usePricing';
import { useTranslation } from 'react-i18next';

function ExperimentPage() {
  const { experimentId, stepId } = useParams<
    {
      experimentId: string;
      stepId: string;
    }>();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { experiments, pricePerPerson, answers, setAnswer, resetAnswers, userData } = useContext(AppContext);
  const [experiment, setExperiment] = useState<Experiment>();

  const booking = (answers[experimentId ?? "-1"]?.[-1]?.[-1] ?? {}) as BookingDetails;
  const price = usePricing(booking);


  useEffect(() => {
    setExperiment(experiments.find((experiment) => experiment.id.toString() === experimentId));
  }, [experimentId, experiments]);

  useEffect(() => {
    if (answers[experimentId ?? "-1"]?.[0]?.[META_EXPERIMENT_END_TIME]) {
      liectApi.postResults({
        data: {
          data: JSON.parse(JSON.stringify({
            ...answers[experimentId ?? "-1"],
            userData: userData,
            totalPrice: price,
            pricePerPerson: pricePerPerson?.[booking.stars]
          })),
          experiment: { id: parseInt(experimentId ?? "-1") }
        }
      }, {
        queries: {
          populate: [],
        }
      });
      resetAnswers();
      navigate(`/`);
      alert(t("thanks_for_participating"));
    }
  }, [answers, booking.stars, experimentId, navigate, price, pricePerPerson, resetAnswers, setAnswer, userData, t]);

  useEffect(() => {
    //pricePerPerson should be set
    //if not, redirect to main page
    if (pricePerPerson === null) {
      navigate(`/`);
    }
  }, [navigate, pricePerPerson]);


  return (
    <div className="App">
      <header className="App-header">
        <h1>&nbsp;</h1>
      </header>
      {
        stepId === '1' && (
          <QuestionaireView
            questionaire={experiment?.attributes.questionaire_before.data}
            onNext={
              () => {
                experiment?.attributes.room.data.attributes.video.data && navigate(`/experiment/${experimentId}/step/2`);
                !experiment?.attributes.room.data.attributes.video.data && navigate(`/experiment/${experimentId}/step/3`);
              }
            }
            onBack={
              () => navigate(`/`)
            } />
        )
      }
      {
        stepId === '2' && (
          <RoomVideoView
            room={experiment?.attributes.room.data}
            onNext={
              () => navigate(`/experiment/${experimentId}/step/3`)
            }
            onBack={
              () => navigate(`/experiment/${experimentId}/step/1`)
            } />
        )
      }
      {
        stepId === '3' && (
          <RoomDetailsView
            room={experiment?.attributes.room.data}
            onNext={
              () => navigate(`/experiment/${experimentId}/step/4`)
            }
            onBack={
              () => {
                experiment?.attributes.room.data.attributes.video.data && navigate(`/experiment/${experimentId}/step/2`);
                !experiment?.attributes.room.data.attributes.video.data && navigate(`/experiment/${experimentId}/step/1`);
              }
            } />
        )
      }
      {
        stepId === '4' && (
          <QuestionaireView
            questionaire={experiment?.attributes.questionaire_after.data}
            onNext={
              () => navigate(`/experiment/${experimentId}/step/5`)
            }
            onBack={
              () => navigate(`/experiment/${experimentId}/step/3`)
            } />
        )
      }
      {
        stepId === '5' && (
          <BookingView
            room={experiment?.attributes.room.data}
            onBook={
              () => {
                const booking = (answers[experimentId ?? "-1"]?.[-1]?.[-1] ?? {}) as BookingDetails;
                const newBooking: BookingDetails = {
                  ...booking,
                  wantsToBook: true
                };
                setAnswer(parseInt(experimentId ?? "-1"), -1, -1, newBooking);
                navigate(`/experiment/${experimentId}/step/6`);
              }
            }
            onRefuse={
              () => {
                const booking = (answers[experimentId ?? "-1"]?.[-1]?.[-1] ?? {}) as BookingDetails;
                const newBooking: BookingDetails = {
                  ...booking,
                  wantsToBook: false
                };
                setAnswer(parseInt(experimentId ?? "-1"), -1, -1, newBooking);
                navigate(`/experiment/${experimentId}/step/7`);
              }
            } />
        )
      }
      {
        stepId === '6' && (
          <QuestionaireView
            questionaire={experiment?.attributes.final_questionare.data}
            onNext={
              () => {
                setAnswer(parseInt(experimentId ?? "-1"), 0, META_EXPERIMENT_END_TIME, new Date().toISOString());
              }
            }
            onBack={
              () => navigate(`/experiment/${experimentId}/step/5`)
            } />
        )
      }
      {
        stepId === '7' && (
          <QuestionaireView
            questionaire={experiment?.attributes.questionaire_reject.data}
            onNext={
              () => {
                navigate(`/experiment/${experimentId}/step/6`)
              }
            } />
        )
      }
    </div>
  );
}

export default ExperimentPage;
