import { useContext } from "react";
import { AppContext, BookingDetails, PricingOptions } from "./AppContext";


export const usePricing = ({ adults, children, stars, nights, selectedOptions }: BookingDetails): { totalPricing: number } => {
    const { pricePerPerson } = useContext(AppContext);
    const price = pricePerPerson?.[stars] ?? null;
    if (price === null) {
        return {
            totalPricing: 0
        };
    }

    let adultPricing = 0;
    if (adults <= 2) {
        adultPricing = adults * price;
    }
    else {
        adultPricing = 2 * price + (adults - 2) * price * 0.85;
    }

    const childrenPricing = children * price * 0.67;
    let totalPricingPerNight = adultPricing + childrenPricing;
    if (nights >= 4) {
        totalPricingPerNight *= 0.95;
    }
    else if (nights >= 7) {
        totalPricingPerNight *= 0.9;
    }

    const options: Record<
        PricingOptions,
        (totalPrice: number) => number
    > = {
        with_view: (totalPrice: number) => totalPrice * 0.15,
        breakfast: (_totalPrice: number) => price * (adults + children * 0.67) * nights * 0.2,
        half_board: (_totalPrice: number) => price * (adults + children * 0.67) * nights * 0.425,
        full_board: (_totalPrice: number) => price * (adults + children * 0.67) * nights * 0.65,
        free_cancellation: (totalPrice: number) => totalPrice * 0.03,
    };

    const totalPriceWithoutOptions = totalPricingPerNight * nights;
    if(!Array.isArray(selectedOptions)) {
        selectedOptions = [];
    }
    const optionsPrice = selectedOptions.reduce((acc, option) => {
        return acc + (options[option] ? options[option](totalPriceWithoutOptions) : 0);
    }, 0);

    return {
        totalPricing: totalPriceWithoutOptions + optionsPrice,
    };
}