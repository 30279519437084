import MainPage from './pages/MainPage';
import { AppContext, AppContextType, ExperimentAnswers, UserData } from './AppContext';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { useEffect, useState } from 'react';
import { Experiment, Question } from './types';
import ExperimentPage from './pages/ExperimentPage';
import { ToastContainer } from 'react-toastify';
import axios from 'axios';

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from './loc/en.json';
import es from './loc/es.json';
import ca from './loc/ca.json';
import ICU from 'i18next-icu';

i18n
  .use(ICU)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: en
      },
      es: {
        translation: es
      },
      ca: {
        translation: ca
      },
    },
    lng: "es",
    fallbackLng: "en",

    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  });

const router = createBrowserRouter([
  {
    path: "/",
    element: <MainPage />,
  },
  {
    path: "/experiment/:experimentId/step/:stepId",
    element: <ExperimentPage />,
  },
]);

export const App = () => {
  const [hiddenQuestions, setHiddenQuestions] = useState<Question[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [experiments, setExperiments] = useState<Experiment[]>([]);
  const [answers, setAnswers] = useState<ExperimentAnswers>({});
  const [pricePerPerson, setPricePerPerson] = useState<Record<number, number> | null>(null);
  const [userData, setUserData] = useState<UserData>({
    country: "",
    ip: "",
    country_code: "",
  });

  //{"country_code":"ES","country_name":"Spain","city":null,"postal":null,"latitude":40.4172,"longitude":-3.684,"IPv4":"213.94.42.169","state":null}
  useEffect(() => {
    axios.get<{
      country_code: string;
      country_name: string;
      latitude: number;
      longitude: number;
      IPv4: string;
    }>('https://geolocation-db.com/json/')
      .then(response => {
        const { data } = response;
        setUserData({
          country: data.country_name,
          ip: data.IPv4,
          country_code: data.country_code,
        });
      })
      .catch(error => console.log(error))
  }, [])



  const appContext: AppContextType = {
    hiddenQuestions,
    setHiddenQuestions,
    pricePerPerson,
    setPricePerPerson,
    userData,
    setUserData,
    answers,
    resetAnswers() {
      setAnswers({});
    },
    setAnswer(experimentId, stepId, questionId, answer) {
      const newAnswers: ExperimentAnswers = { ...answers };
      if (!newAnswers[experimentId]) {
        newAnswers[experimentId] = {};
      }
      if (!newAnswers[experimentId][stepId]) {
        newAnswers[experimentId][stepId] = {};
      }
      if (!answer)
        delete newAnswers[experimentId][stepId][questionId];
      else
        newAnswers[experimentId][stepId][questionId] = answer;
      setAnswers(newAnswers);
    },
    experiments,
    setExperiments,
    isLoading,
    setIsLoading,
  };

  return (
    <AppContext.Provider value={appContext}>
      <ToastContainer pauseOnFocusLoss={false} newestOnTop />
      <RouterProvider router={router} />
    </AppContext.Provider>
  )
}