import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import { Experiment } from '../types';
import { useTranslation } from 'react-i18next';

function AgreementModal({ experiment, onAgree, onRefuse, isOpen }: { experiment?: Experiment, onAgree?: () => void, onRefuse?: () => void, isOpen: boolean }) {

    const { t } = useTranslation();

    return (
        <Modal size='lg' backdrop fade centered scrollable isOpen={isOpen} style={{ color: "black" }}>
            <ModalHeader>{t('agreement')}</ModalHeader>
            <ModalBody>
                {experiment?.attributes.agreement}
            </ModalBody>
            <ModalFooter>
                <Button color="success" onClick={onAgree}>{t('accept')}</Button>
                <Button color="secondary" onClick={onRefuse}>{t('decline')}</Button>
            </ModalFooter>
        </Modal >
    );
}

export default AgreementModal;
