import { Button } from 'reactstrap';
import './MainPage.css';
import { useContext, useEffect, useState } from 'react';

import { AppContext } from '../AppContext';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { liectApi } from '../apis/liect.org';
import AgreementModal from '../views/AgreementModal';
import { Experiment } from '../types';

export const META_EXPERIMENT_START_TIME = 1;
export const META_EXPERIMENT_END_TIME = 2;

function MainPage() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();


  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { experiments, setAnswer, setPricePerPerson, setExperiments, isLoading } = useContext(AppContext);
  const [selectedExperiment, setSelectedExperiment] = useState<Experiment | null>(null);
  const [startExperiment, setStartExperiment] = useState<boolean>(false);

  const { language } = i18n;

  useEffect(() => {
    if (!startExperiment || experiments.length > 0) {
      return;
    }
    liectApi.getExperiments({
      queries: {
        locale: language,
        populate: [
          "questionaire_before.questions.answers",
          "questionaire_after.questions.answers",
          "questionaire_reject.questions.answers",
          "final_questionare.questions.answers",
          "room.images",
          "room.main_image",
          "room.video",
          "room.options",
        ],
      }
    }).then((res) => {
      setExperiments(res.data);
    });
  }, [language, experiments]);

  useEffect(() => {
    if (experiments.length === 0) {
      return;
    }
    const randomExperiment = experiments[Math.floor(Math.random() * experiments.length)];
    setSelectedExperiment(randomExperiment);
    if (startExperiment) {
      setIsModalOpen(true);
    }
  }, [experiments]);

  return (
    <>
      <AgreementModal
        isOpen={isModalOpen && !isLoading}
        experiment={selectedExperiment ?? undefined}
        onAgree={() => {
          if (!selectedExperiment) {
            return;
          }
          setIsModalOpen(false);
          setAnswer(selectedExperiment?.id ?? -1, 0, META_EXPERIMENT_START_TIME, new Date().toISOString());
          navigate(`/experiment/${selectedExperiment?.id ?? -1}/step/1`);
        }}
        onRefuse={() => {
          setIsModalOpen(false);
        }}
      />
      <div className="App" style={{ minHeight: "100vh" }}>
        <header className="App-header">
          <div className='d-flex align-items-center justify-content-center'>
            {experiments &&
              <>
                <div className='experiment-container'>
                  {/* <h1>{randomExperiment?.attributes.name}</h1> */}
                  <p style={{ fontSize: '0.5em' }}>{t('start_the_experiment', { lng: "en" })}</p>
                  <Button onClick={() => {
                    setExperiments([]);
                    i18n.changeLanguage("en");
                    setPricePerPerson({
                      1: (Math.floor(Math.random() * 20) + 15),
                      2: (Math.floor(Math.random() * 25) + 25),
                      3: (Math.floor(Math.random() * 30) + 35),
                      4: (Math.floor(Math.random() * 35) + 45),
                      5: (Math.floor(Math.random() * 40) + 55),
                    });
                    setStartExperiment(true);
                  }} color="success" className='m-2'>{t('start', { lng: "en" })}</Button>
                </div>
                <div className='experiment-container'>
                  {/* <h1>{randomExperiment?.attributes.name}</h1> */}
                  <p style={{ fontSize: '0.5em' }}>{t('start_the_experiment', { lng: "es" })}</p>
                  <Button onClick={() => {
                    setExperiments([]);
                    i18n.changeLanguage("es");
                    setPricePerPerson({
                      1: (Math.floor(Math.random() * 20) + 15),
                      2: (Math.floor(Math.random() * 25) + 25),
                      3: (Math.floor(Math.random() * 30) + 35),
                      4: (Math.floor(Math.random() * 35) + 45),
                      5: (Math.floor(Math.random() * 40) + 55),
                    });
                    setStartExperiment(true);
                  }} color="success" className='m-2'>{t('start', { lng: "es" })}</Button>
                </div>
                <div className='experiment-container'>
                  {/* <h1>{randomExperiment?.attributes.name}</h1> */}
                  <p style={{ fontSize: '0.5em' }}>{t('start_the_experiment', { lng: "ca" })}</p>
                  <Button onClick={() => {
                    setExperiments([]);
                    i18n.changeLanguage("ca");
                    setPricePerPerson({
                      1: (Math.floor(Math.random() * 20) + 15),
                      2: (Math.floor(Math.random() * 25) + 25),
                      3: (Math.floor(Math.random() * 30) + 35),
                      4: (Math.floor(Math.random() * 35) + 45),
                      5: (Math.floor(Math.random() * 40) + 55),
                    });
                    setStartExperiment(true);
                  }} color="success" className='m-2'>{t('start', { lng: "ca" })}</Button>
                </div>
                </>
            }


          </div>
        </header>
      </div>
    </>
  );
}

export default MainPage;
