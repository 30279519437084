import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import { useTranslation } from 'react-i18next';

function WarningModal({ text, title, onAgree, onRefuse, isOpen }: { text: string, title: string, onAgree?: () => void, onRefuse?: () => void, isOpen: boolean }) {

    const { t } = useTranslation();

    return (
        <Modal size='lg' backdrop fade centered scrollable isOpen={isOpen} style={{ color: "black" }}>
            <ModalHeader>{title}</ModalHeader>
            <ModalBody>
                {text}
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={onRefuse}>{t('no')}</Button>
                <Button color="secondary" onClick={onAgree}>{t('yes_continue')}</Button>
            </ModalFooter>
        </Modal >
    );
}

export default WarningModal;
