import { Zodios, makeParameters } from "@zodios/core";
import { ExperimentSchema, QuestionaireSchema, StrapiFieldsSchema, StrapiMetaSchema, StrapiPopulateSchema, StrapiReference } from "../types";
import { z } from "zod";


const StrapiDefaultParams = makeParameters([
    {
        name: "locale",
        type: "Query",
        schema: z.string().optional()
    },
    {
        name: "populate",
        type: "Query",
        schema: StrapiPopulateSchema,
    },
    {
        name: "fields",
        type: "Query",
        schema: StrapiFieldsSchema,
    }
]);


const StrapiResultParams = makeParameters([
    {
        name: "data",
        type: "Body",
        schema: z.object({
            data: z.object({
                experiment: StrapiReference,
                data: z.object({}).passthrough()
            })
        })
    }
]);

const StrapiResultResponse = z.object({
    id: z.number(),
    attributes: z.object({
        experiment: StrapiReference.optional(),
        data: z.object({}).passthrough()
    }).passthrough()
});

export const liectApi = new Zodios(
    process.env.REACT_APP_SERVER_API_URL as string,
    [
        {
            method: "get",
            path: "/experiments",
            alias: "getExperiments",
            description: "Get experiments",
            response: z.object({
                data: z.array(ExperimentSchema),
                meta: StrapiMetaSchema
            }),
            parameters: [
                ...StrapiDefaultParams
            ]
        },
        {
            method: "get",
            path: "/questionaires",
            alias: "getQuestionaires",
            description: "Get questionaires",
            response: z.object({
                data: z.array(QuestionaireSchema),
                meta: StrapiMetaSchema
            }),
            parameters: [
                ...StrapiDefaultParams
            ]
        },
        {
            method: "post",
            path: "/results",
            alias: "postResults",
            description: "Post results",
            response: z.object({
                data: StrapiResultResponse,
                meta: StrapiMetaSchema
            }),
            parameters: [
                ...StrapiDefaultParams,
                ...StrapiResultParams,
            ]
        }
    ],
);
