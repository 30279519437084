import ReactPlayer from "react-player";
import { findDOMNode } from 'react-dom';
import './VideoRenderer.css';
import React, { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import { Button } from "reactstrap";

export const VideoRenderer = ({
  videoUrl,
  onEnded
}: {
  videoUrl: string;
  onEnded?: () => void;
}) => {
  const [play, setPlay] = useState<boolean>(true);
  const videoRef = React.useRef<ReactPlayer>(null);


  useEffect(() => {
    if (play) {
      (findDOMNode(videoRef.current) as Element)?.requestFullscreen().catch(
        (err) => { toast.error("Could not activate full-screen mode :(") }
      );
    }
    else {
      document.exitFullscreen().catch(
        (err) => { }
      );
    }
  }, [play]);


  useEffect(() => {
    const exitHandler = () => {
      if (!document.fullscreenElement) {
        if (play) {
          setPlay(false);
        }
      }
    }
    document.addEventListener('fullscreenchange', exitHandler);
    return () => {
      document.removeEventListener('fullscreenchange', exitHandler);
    }
  });



  return (
    <div className='player-wrapper'>
      <ReactPlayer
        ref={videoRef}
        className='react-player'
        url={videoUrl}
        width='100%'
        height='100%'
        controls={false}
        playing={play}
        onPlay={() => {
          setPlay(true);
        }}
        onPause={() => {
          setPlay(false);
        }}
        onEnded={() => {
          setPlay(false);
          onEnded?.();
        }}
      />
      <div className={`d-flex align-items-center justify-content-center position-absolute ${play === true ? 'hidden' : ''}`} onClick={() => setPlay(true)} style={{
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        margin: " auto"
      }}>
        <Button color="primary"> Play video </Button>
      </div>
    </div>
  );
};