import { Button } from 'reactstrap';
import { VideoRenderer } from '../components/VideoRenderer';
import { Room } from '../types';
import { useTranslation } from 'react-i18next';

function RoomVideoView({ room, onNext, onBack }: { room?: Room, onNext?: () => void, onBack?: () => void }) {
    const { t } = useTranslation();
    return (
        <div style={{ maxWidth: "800px", alignSelf: "center" }}>
            <h3 style={{ marginBottom: "3em" }}>{room?.attributes.name}</h3>
            <div>
                {
                    room?.attributes.video.data && (
                        <div className='m-2' style={{ width: '500px', height: '300px' }}>
                            <VideoRenderer
                                videoUrl={room.attributes.video.data.attributes.url}
                                onEnded={onNext}
                            />
                        </div>
                    )
                }
            </div>
            <div className='d-flex  justify-content-between mt-4' style={{ borderTop: '1px white solid', padding: 10 }}>
                <Button color="danger" onClick={onBack}>{t('back')}</Button>
                <Button color="success" onClick={onNext}>{t('next')}</Button>
            </div>
        </div>
    );
}

export default RoomVideoView;
