import { Button, Input } from 'reactstrap';

import { Question } from '../types';
import { useParams } from 'react-router-dom';
import { useContext } from 'react';
import { AppContext } from '../AppContext';
import { t } from 'i18next';

function QuestionRenderer({ question, number }: { question: Question, number: number }) {
  const { stepId = "-1", experimentId = "-1" } = useParams<
    {
      experimentId: string;
      stepId: string;
    }>();

  const { setAnswer, answers } = useContext(AppContext);

  switch (question.__component) {
    case 'dynamic.agreement-question':
      return (
        <div>
          <p style={{ textAlign: 'justify' }}>{question.question}</p>
          <div className='d-flex justify-content-between'>
            <Button
              color={answers[experimentId]?.[stepId]?.[number] === 'strongly_agree' ? "info" : "secondary"}
              onClick={(event) => setAnswer(parseInt(experimentId), parseInt(stepId), number, "strongly_agree")}
            >Totalmente de acuerdo</Button>
            <Button
              color={answers[experimentId]?.[stepId]?.[number] === 'agree' ? "info" : "secondary"}
              onClick={(event) => setAnswer(parseInt(experimentId), parseInt(stepId), number, "agree")}
            >De acuerdo</Button>
            <Button
              color={answers[experimentId]?.[stepId]?.[number] === 'neutral' ? "info" : "secondary"}
              onClick={(event) => setAnswer(parseInt(experimentId), parseInt(stepId), number, "neutral")}
            >Ni de acuerdo ni en desacuerdo</Button>
            <Button
              color={answers[experimentId]?.[stepId]?.[number] === 'disagree' ? "info" : "secondary"}
              onClick={(event) => setAnswer(parseInt(experimentId), parseInt(stepId), number, "disagree")}
            >En desacuerdo</Button>
            <Button
              color={answers[experimentId]?.[stepId]?.[number] === 'strongly_disagree' ? "info" : "secondary"}
              onClick={(event) => setAnswer(parseInt(experimentId), parseInt(stepId), number, "strongly_disagree")}
            >Totalmente en desacuerdo</Button>
          </div>
        </div>
      );
    case 'dynamic.numeric-input-question':
      return (
        <div className='d-flex justify-content-between flex-grow-1'>
          <p className='text-start me-2'>{question.question}</p>
           <div className='d-flex flex-column justify-content-around'>
          <Input style={{ height: "48px", width: "200px" }} type="number" min={question.min ?? undefined} max={question.max ?? undefined} placeholder={(Number.isFinite(question.min) && Number.isFinite(question.max)) ? `${question.min} - ${question.max}` : undefined} value={answers[experimentId]?.[stepId]?.[number] as string ?? ''} onChange={(event) => setAnswer(parseInt(experimentId), parseInt(stepId), number, event.target.value)} />
          </div>
        </div>
      );
    case 'dynamic.percentage-question':
      return (
        <div className='d-flex justify-content-between flex-grow-1'>
          <p className='text-start me-2'>{question.question}</p>
          <div className='d-flex flex-column justify-content-around' style={{ width: '900px' }}>
            {
              question.answers.map((answer) => {

                const currentAnswers = answers[experimentId]?.[stepId]?.[number] ? answers[experimentId]?.[stepId]?.[number] as Record<string, string> : {};
                const currentAnswer = currentAnswers[answer.label];

                return (
                  <div className='d-flex flex-row align-items-center justify-content-between'>{answer.label}
                    <Input
                      type='number'
                      min={0}
                      max={100}
                      placeholder='0-100%'
                      className='ms-4 justify-self-end'
                      style={{ width: '100px' }}
                      value={currentAnswer || undefined}
                      onChange={(event) => {
                        setAnswer(parseInt(experimentId), parseInt(stepId), number, {
                          ...currentAnswers,
                          [answer.label]: event.target.value
                        });
                      }} />
                  </div>
                );
              })
            }
          </div>
        </div>
      );
    case 'dynamic.question':
      if (question.multiple_answers) {
        return (
          <div className='d-flex justify-content-between flex-grow-1 flex-column'>
            <p className='text-start me-2'>{question.question}</p>
            <div className='d-flex flex-column justify-content-around' style={{ width: '900px' }}>
              {
                question.answers.map((answer) => {

                  const currentAnswers = answers[experimentId]?.[stepId]?.[number] ? answers[experimentId]?.[stepId]?.[number] as Record<string, string> : {};
                  const currentAnswer = currentAnswers[answer.text];

                  return (
                    <div className='d-flex flex-row align-items-center align-right justify-content-end'>{answer.text}
                      <Input
                        type='checkbox'
                        className='ms-4 justify-self-end'
                        checked={currentAnswer === 'true'}
                        onChange={(event) => {
                          setAnswer(parseInt(experimentId), parseInt(stepId), number, {
                            ...currentAnswers,
                            [answer.text]: event.target.checked ? 'true' : 'false'
                          });
                        }} />
                    </div>
                  );
                })
              }
            </div>
          </div>);
      }
      else {
        return (
          <div className='d-flex justify-content-between flex-grow-1'>
            <p className='text-start me-2 '>{question.question}</p>
            <div className='d-flex flex-column justify-content-around'>
              <Input style={{ height: "48px", width: "200px" }} type="select" value={answers[experimentId]?.[stepId]?.[number] as string ?? ''} onChange={(event) => setAnswer(parseInt(experimentId), parseInt(stepId), number, event.target.value)}>
                <option value='' disabled>{t('pick_an_answer')}</option>
                {
                  question.answers.map((answer) => {
                    return (
                      <option key={answer.id.toString()} value={answer.text}>{answer.text}</option>
                    );
                  })
                }
              </Input>
            </div>
          </div>
        );
      }
    case 'dynamic.question-description':
      return (
        <div className='flex-grow-1'>
          <h5 className='text-start mt-4'>{question.text}</h5>
        </div>
      );

    default:
      return (
        <div>
          <p>Unknown question type</p>
        </div>
      );
  }
}

export default QuestionRenderer;
