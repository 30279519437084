import { Dispatch, SetStateAction, createContext } from 'react';
import { Experiment, Question } from './types';


export type PricingOptions = 'with_view' | 'breakfast' | 'half_board' | 'full_board' | 'free_cancellation';
export type ExperimentAnswers = Record<string, Record<string, Record<string, string | Record<string, string | string[] | number | boolean>>>>; //experimentId -> stepId -> questionId -> answer
export type UserData = {
    country: string;
    ip: string;
    country_code: string;
}

export type BookingDetails = {
    adults: number;
    children: number;
    stars: number;
    nights: number;
    selectedOptions: PricingOptions[];
    wantsToBook: boolean;
};

export type AppContextType = {
    hiddenQuestions: Question[];
    setHiddenQuestions: Dispatch<SetStateAction<Question[]>>;
    pricePerPerson: Record<number, number> | null;
    setPricePerPerson: Dispatch<SetStateAction<Record<number, number> | null>>;
    userData: UserData;
    setUserData: Dispatch<SetStateAction<UserData>>;
    setAnswer: (experimentId: number, stepId: number, questionId: number, answer: string | Record<string, string | string[] | boolean | number> | null) => void;
    resetAnswers: () => void;
    answers: ExperimentAnswers;
    experiments: Experiment[];
    setExperiments: Dispatch<SetStateAction<Experiment[]>>;
    isLoading: boolean;
    setIsLoading: Dispatch<SetStateAction<boolean>>;
}

export const AppContext = createContext<AppContextType>({
    resetAnswers: () => { },
    hiddenQuestions: [],
    setHiddenQuestions: () => { },
    pricePerPerson: null,
    setPricePerPerson: () => { },
    userData: {
        country: '',
        ip: '',
        country_code: '',
    },
    setUserData: () => { },
    answers: {},
    setAnswer: () => { },
    experiments: [],
    setExperiments: () => { },
    isLoading: true,
    setIsLoading: () => { },
});