import { Button, Col, Container, Row } from 'reactstrap';

import QuestionRenderer from '../components/QuestionRenderer';
import { Questionaire } from '../types';
import { useContext, useEffect, useState } from 'react';
import { AppContext, BookingDetails } from '../AppContext';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import WarningModal from './WarningModal';

function QuestionaireView({ questionaire, onNext, onBack }: { questionaire?: Questionaire, onNext?: () => void, onBack?: () => void }) {
    const { t } = useTranslation();
    const { answers, hiddenQuestions, setHiddenQuestions, setAnswer } = useContext(AppContext);
    const [showWarning, setShowWarning] = useState<boolean>(false);
    const { stepId = "-1", experimentId = "-1" } = useParams<
        {
            experimentId: string;
            stepId: string;
        }>();


    //if question Nacionalidad is not "Española", then hide question "Lugar de residencia"
    useEffect(() => {
        if (!questionaire) {
            return;
        }

        const nacionalidadQuestion = questionaire?.attributes.questions.find((question) => question.__component === "dynamic.question" && question.question?.includes(t('nationality_question_part')));
        const lugarDeResidenciaQuestion = questionaire?.attributes.questions.find((question) => question.__component === "dynamic.question" && question.question?.includes(t('residence_question_part')));
        if (nacionalidadQuestion && lugarDeResidenciaQuestion) {
            const nacionalidadQuestionIndex = questionaire.attributes.questions.indexOf(nacionalidadQuestion);
            const isSpain = (answers[experimentId]?.[stepId]?.[nacionalidadQuestionIndex + 1]??"").toString().includes(t('spanish_answer'));
            const question = questionaire.attributes.questions[questionaire.attributes.questions.indexOf(lugarDeResidenciaQuestion)];
            if (!isSpain)
                setHiddenQuestions(hiddenQuestions => [...hiddenQuestions.filter((q) => q !== question), question]);
            else
                setHiddenQuestions(hiddenQuestions => hiddenQuestions.filter((q) => q !== question));

        }
    }, [answers, experimentId, questionaire, stepId, setHiddenQuestions, t]);

    const validateQuestionaire = () => {
        if (!questionaire) {
            return {
                canContinue: false,
                canContinueWithWarning: false
            };
        }

        const missingAnswers = questionaire.attributes.questions.some((question, i) => {
            if (question.__component === 'dynamic.question-description') {
                return false;
            }
            if (question.__component === 'dynamic.question') {
                if (hiddenQuestions.includes(question)) {
                    return false;
                }
            }
            if (!answers[experimentId]?.[stepId]?.[i + 1]) {
                return true;
            }
            //if a percentage question, totals must be 100
            if (question.__component === 'dynamic.percentage-question') {
                const total = Object.values(answers[experimentId][stepId]?.[i + 1]).reduce<number>((acc, curr) => {
                    return acc + parseInt(curr.toString());
                }, 0);
                return total !== 100;
            }

            return false;
        });

        const missingMandatoryAnswers = questionaire.attributes.questions.some((question, i) => {
            if (question.__component === 'dynamic.question-description') {
                return false;
            }
            //if not mandatory, skip
            if (!question.mandatory) {
                return false;
            }

            if (question.__component === 'dynamic.question') {
                if (hiddenQuestions.includes(question)) {
                    return false;
                }
            }
            if (!answers[experimentId]?.[stepId]?.[i + 1]) {
                toast.error(t('missing_answers'));
                return true;
            }
            //if a percentage question, totals must be 100
            if (question.__component === 'dynamic.percentage-question') {
                const total = Object.values(answers[experimentId][stepId]?.[i + 1]).reduce<number>((acc, curr) => {
                    return acc + parseInt(curr.toString());
                }, 0);
                if (total !== 100) {
                    toast.error(t('percentage_must_be_100'));
                }
                return total !== 100;
            }

            return false;
        });



        const MAX_TRAVELERS = 4;
        //validate that total is maxTravelers or less
        const questionsToSum = [t('adult_question_part'), t('children_question_part')];
        const travelerQuestions = questionaire.attributes.questions
            .filter((question) => {
                if (question.__component === 'dynamic.numeric-input-question') {
                    return questionsToSum.some((questionToSum) => question.question?.includes(questionToSum));
                }
                return false;
            });
        const nightsQuestion = questionaire.attributes.questions.find((question) => question.__component === 'dynamic.numeric-input-question' && question.question?.includes(t('number_of_nights_question_part')));
        const nights = (nightsQuestion && answers[experimentId][stepId]?.[questionaire.attributes.questions.indexOf(nightsQuestion) + 1] as string) ?? "0";

        const starsQuestion = questionaire.attributes.questions.find((question) => question.__component === 'dynamic.question' && question.question?.includes(t('number_of_stars_question_part')));
        const stars = (starsQuestion && answers[experimentId][stepId]?.[questionaire.attributes.questions.indexOf(starsQuestion) + 1] as string) ?? "0";

        const travelerQuestionAnswers = travelerQuestions.map((question) => {
            return (answers[experimentId][stepId]?.[questionaire.attributes.questions.indexOf(question) + 1] ?? "0") as string;
        });
        const totalTravelers = travelerQuestionAnswers.reduce((acc, curr) => {
            return acc + parseInt(curr);
        }, 0);
        if (totalTravelers > MAX_TRAVELERS) {
            toast.error(t('max_travelers_error', { max: MAX_TRAVELERS }));
        }

        if (travelerQuestionAnswers.length !== 0) {
            const booking: BookingDetails = {
                adults: parseInt(travelerQuestionAnswers[0]),
                children: parseInt(travelerQuestionAnswers[1]),
                nights: parseInt(nights),
                selectedOptions: [],
                stars: parseInt(stars),
                wantsToBook: false
            };
            setAnswer(parseInt(experimentId), -1, -1, booking);
        }
        return {
            canContinue: !missingAnswers && !missingMandatoryAnswers && totalTravelers <= MAX_TRAVELERS,
            canContinueWithWarning: missingAnswers && !missingMandatoryAnswers && totalTravelers <= MAX_TRAVELERS //if missing answers, but not mandatory, then continue with warning
        };
    }

    return (
        <Container style={{ alignSelf: "center" }}>
            <h3>{questionaire?.attributes.title}</h3>
            <h6 style={{ marginBottom: "3em" }}>{questionaire?.attributes.description}</h6>
            <Container>
                {
                    questionaire?.attributes.questions.map((question, i) => {
                        if (hiddenQuestions.includes(question)) {
                            return null;
                        }

                        const isAnswered = answers[experimentId]?.[stepId]?.[i + 1] !== undefined;
                        const isMandatory = question.__component !== 'dynamic.question-description' && !(question.__component === 'dynamic.question' && question.multiple_answers) && question.mandatory;
                        const isNotQuestion = question.__component === 'dynamic.question-description';
                        return (<Row>
                            <Col className='d-flex align-items-center' key={i}>
                                <QuestionRenderer question={question} number={i + 1} />
                                {!isNotQuestion && (isMandatory ?
                                    (isAnswered ?
                                        <i className="ms-2 fa-solid fa-check" style={{ color: 'green' }}></i> :
                                        <i className="ms-2 fa-solid fa-star-of-life" style={{ color: 'red' }}></i>
                                    ) :
                                    (isAnswered ?
                                        <i className="ms-2 fa-solid fa-check" style={{ color: 'green' }}></i> :
                                        <i className="ms-2 fa-solid fa-triangle-exclamation" style={{ color: "gray" }}></i>))
                                }
                            </Col>
                            <hr className='m-2' />
                        </Row>);
                    })
                }
            </Container>
            <div className='d-flex  justify-content-between mt-4' style={{ borderTop: '1px white solid', padding: 10 }}>
                {onBack && <Button color="danger" onClick={onBack}>{t('back')}</Button>}
                <Button color="success" onClick={() => {
                    const { canContinue, canContinueWithWarning } = validateQuestionaire();
                    if (canContinue) {
                        onNext?.();
                    }
                    else if (canContinueWithWarning) {
                        setShowWarning(true);
                    }
                }}>{t('next')}</Button>
            </div>
            <WarningModal isOpen={showWarning} onAgree={() => {
                setShowWarning(false);
                onNext?.();
            }} onRefuse={() => {
                setShowWarning(false);
            }} text={t('warning_message')} title={t('warning_message_title')} />
        </Container >
    );
}

export default QuestionaireView;
