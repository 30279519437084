import { Button, Col, Container, Row } from 'reactstrap';
import { Room } from '../types';
import "./RoomDetailsView.css";
import ImageGallery from "react-image-gallery";
import { useTranslation } from 'react-i18next';

function RoomDetailsView({ room, onNext, onBack }: { room?: Room, onNext?: () => void, onBack?: () => void }) {
    const { t } = useTranslation();
    return (
        <div>
            <h3>{t('click_next_after_examining')}</h3>

            <Container>
                <Row>
                    {
                        room?.attributes.images.data && (
                            <Col xs={12} lg={8}>
                                <ImageGallery
                                    items={
                                        room.attributes.images.data.map((image) => {
                                            return {
                                                original: image.attributes.formats?.large?.url || image.attributes.formats?.medium?.url || image.attributes.url,
                                                thumbnail: image.attributes.formats?.thumbnail?.url || image.attributes.url,
                                                fullscreen: image.attributes.url,
                                            }
                                        })
                                    }
                                    renderItem={(item) => (
                                        <img className='image-gallery-image' src={item.original} alt={item.originalAlt} srcSet={item.srcSet} sizes={item.sizes} />
                                    )
                                    }
                                    showPlayButton={false}
                                />
                            </Col>
                        )
                    }
                    {
                        room?.attributes.description && (
                            <Col xs={12} lg={4}>
                                <p className='html-description' style={{ whiteSpace: "pre-wrap", textAlign: "left" }} dangerouslySetInnerHTML={{ __html: room?.attributes.description.replaceAll("fa- solid", "fa-solid") }}></p>
                            </Col>
                        )
                    }
                </Row>
            </Container>
            <div className='d-flex  justify-content-between mt-4' style={{ borderTop: '1px white solid', padding: 10 }}>
                <Button color="danger" onClick={onBack}>{t('back')}</Button>
                <Button color="success" onClick={onNext}>{t('next')}</Button>
            </div>
        </div>
    );
}

export default RoomDetailsView;
